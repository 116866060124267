<template>
    <div>
        <!--form add & update department-->
        <ek-dialog
            btnText="فرع جديدة"
            ref="dialog"
            :isEdit="!!branchDto.id"
            :title="branchDto.id ? 'تعديل  فرع' : 'اضافة فرع '"
            @delete="deleteDep(branchDto.id)"
            placeholder="ابحث عن فرع محدد"
            @ok="submitForm"
            @close="resetForm"
            @search="setSearch"
        >
            <template #body>
                <validationObserver ref="branchForm">
                    <ek-input-text
                        v-model="branchDto.name"
                        name="اسم "
                        placeholder="ادخل اسم الفرع"
                        label="اسم الفرع"
                    ></ek-input-text>

                    <ek-input-select
                        v-if="!branchDto.id"
                        name="sendTo"
                        placeholder="حدد  الجامعة"
                        label="تابع للجامعة"
                        :options="universityName"
                        v-model="branchDto.universityId"
                    ></ek-input-select>

                    <ek-input-select
                        v-if="branchDto.id"
                        name="sendTo"
                        placeholder="حدد  الجامعة"
                        label="تابع للجامعة"
                        :options="universityName"
                        v-model="branchDto.universityId"
                        @change="resetFaculty"
                    ></ek-input-select>

                    <ek-input-select
                        v-if="branchDto.universityId"
                        name="sendTo"
                        placeholder="حدد الكلية"
                        label="تابع للكلية"
                        :options="
                            universityName.find(
                                (u) => u.id == branchDto.universityId
                            ).faculties
                        "
                        v-model="branchDto.facultyId"
                    ></ek-input-select>

                    <ek-input-text
                        v-model="branchDto.yearCount"
                        name="اسم "
                        placeholder="ادخل  عدد السنوات"
                        label=" عدد السنوات"
                        :rules="[
                            { type: 'required', message: 'عدد السنوات  مطلوب' },
                        ]"
                    ></ek-input-text>
                </validationObserver>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    data() {
        return {
            nameFaculty: "",
            university: {
                id: "",
                name: "",
                faculties: [],
            },
        };
    },
    computed: {
        ...mapState({
            branchDto: (state) => state.branches.branchDto,
            isDialogOpen: (state) => state.branches.isDialogOpen,
            universityName: (state) => state.university.universityName,
            facultiesList: (state) => state.faculty.facultyName,
        }),
    },
    methods: {
        ...mapActions(["addBranch", "updateBranch"]),
        //submit add & update department
        submitForm() {
            this.$refs.branchForm.validate().then((suc) => {
                if (suc) {
                    if (this.branchDto.id) {
                        this.updateBranch(this.branchDto);
                        this.$refs.dialog.close();
                    } else {
                        this.addBranch(this.branchDto)
                            .then(() => {
                                this.$refs.dialog.close();
                            })
                            .catch(() => {});
                    }
                }
            });
        },
        resetFaculty() {
            this.branchDto.facultyId = "";
        },
        deleteDep(id) {
            this.$store.dispatch("deleteDepartments", [id]);
        },
        resetForm() {
            this.$refs.branchForm.reset();
            this.$store.commit("SET_BRANCH_DTO");
            this.$store.commit("IS_DIALOG_OPEN", false);
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name", "facultiesCount", "studentsCount"],
                query,
            });
        },
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>

<style></style>
